header {
	margin-top: 12px;
}

article {

}

footer {

}

.page-camera-setup .camera-display-holder {
	text-align: center;
}

.page-camera-setup .camera-display {
	background: grey;
	width: 100%;
	margin: auto;
}

.camera-image {
	width: 100%;
}


.page {
	display: flex !important;
	justify-content:center;
	align-items: center;
	flex-direction: column;	
	height: 100%;
}

