header {
	margin-top: var(--border-width);
}

article {

}

footer {
	margin: var(--border-width);
}

.page-thank-you {
	height: 100%;;
}

.center-items {
	height: 60%;
	justify-content: center;
	align-items: center;

}