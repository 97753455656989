header {
	margin-top: var(--border-width);
}

article {

}

footer {
	margin: var(--border-width);
}

.page-use-camera .camera-display-holder {
	text-align: center;
}

.page-use-camera .camera-display {
	background: lightgrey;
	height: 300px;
	width: 300px;
	margin: auto;
}

.page-use-camera .camera-controls-holder {
	padding: var(--border-width);
	display: flex;
	justify-content: space-evenly;
}
