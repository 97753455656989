:root {
	--border-width : 12px;
}

.flex {
	display: flex
}

.space-between {
	justify-content: space-between;
}
.space-around {
	justify-content: space-around;
}

.a {
	height: 95%;
}