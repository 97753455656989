header {
	margin-top: var(--border-width);
}

article {

}

footer {
	margin: var(--border-width);
}

.bird {
	background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
	background-size: auto 100%;
	width: 88px;
	height: 125px;
	will-change: background-position;
	
	animation-name: fly-cycle;
	animation-timing-function: steps(10);
	animation-iteration-count: infinite;
}
.bird--one {
	animation-duration: 1s;
	animation-delay: -0.5s;		
}
.bird-container {
	position: absolute;
	top: 20%;
	left: -10%;
	transform: scale(0) translateX(-10vw);
	will-change: transform;
	
	animation-name: fly-right-one;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
.bird-container--one {
	animation-duration: 15s;
	animation-delay: 0;
}
@keyframes fly-cycle {
	100% {
		background-position: -900px 0;
	}	
}
@keyframes fly-right-one {
	0% {
		transform: translateY(0vh) translateX(1vw) scale(0.3) ;
	}
	10% {
		transform: translateY(2vh) translateX(30vw) scale(0.4);
	}
	20% {
		transform: translateY(0vh) translateX(60vw) scale(0.5);
	}
	30% {
		transform: translateY(4vh) translateX(90vw) scale(0.6);
	}
	40% {
		transform: translateY(2vh) translateX(120vw) scale(0.6);
	}
	/* 60% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	} */
	/* 100% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}	 */
}